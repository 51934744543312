import axios, { AxiosError } from "axios";
import addNotification from "./notifications";

const HttpResponse = {
  UNAUTHORIZED: 401
}

const showNotificationError = (message: string) => {
  addNotification({ title: "Erro!", message: message, type: "danger" });
}

const handleConnectionError = (err: any) => {
  const errors = err as Error | AxiosError;

  if (!axios.isAxiosError(errors) || errors.response?.status === 0) {
    const error = err as Error;
    showNotificationError(error.message);

  } else {
    const { status } = err.response;
    const { message } = err.response.data;
    console.log(err.response.data);
    showNotificationError(message || 'Ocorreu um erro.');

    if (status === HttpResponse.UNAUTHORIZED) window.location.href = '/';
    else return err.response.data;
  }
}

export default handleConnectionError;
