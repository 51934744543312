import React from "react";
import { Route, Routes } from "react-router-dom";

import Layout from "./components/Layout";
import Login from "./pages/Login";
import RequireAuth from "./components/RequireAuth";
import roles from "./utils/roles";
import Users from "./pages/Users";
import AdminProfile from "./pages/AdminProfile";
import ClientProfile from "./pages/ClientProfile";
import PasswordChange from "./pages/PasswordChange";
import UserEdit from "./pages/UserEdit";
import PasswordRecovery from "./pages/PasswordRecovery";
import PasswordNew from "./pages/PasswordNew";
import Register from "./pages/Register";
import ActivateAccount from "./pages/ActivateAccount";
import InvitationsSent from "./pages/InvitationsSent";
import InvitationsReceived from "./pages/InvitationsReceived";
import CompanyEmployees from "./pages/CompanyEmployees";
import LoginRedirector from "./components/LoginRedirector";

const Unauth = () => <h1>Sem autorização.</h1>;
const NotFound = () => <h1>Página não existe.</h1>;

const App: React.FC = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/activate" element={<ActivateAccount />} />
      <Route path="/register" element={<Register />} />
      <Route path="/password/recovery" element={<PasswordRecovery />} />
      <Route path="/password/new" element={<PasswordNew />} />

      <Route element={<RequireAuth allowedRoles={[roles.ADMIN, roles.CLIENT]} />}>
        <Route path="/password/change" element={<PasswordChange />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[roles.ADMIN]} />}>
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/users/edit" element={<UserEdit />} />
        <Route path="/admin/profile" element={<AdminProfile />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[roles.CLIENT]} />}>
        <Route path="/login/redirector" element={<LoginRedirector />} />
        <Route path="/client/profile" element={<ClientProfile />} />
        <Route path="/invitations/sent" element={<InvitationsSent />} />
        <Route path="/invitations/received" element={<InvitationsReceived />} />
        <Route path="/employees" element={<CompanyEmployees />} />
      </Route>

      <Route path="unauthorized" element={<Unauth />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

export default App;
