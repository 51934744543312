import Badge from "react-bootstrap/Badge";
import { Container } from "./styles";

interface IBadgeSystemAvailableProps {
  system: string;
  available: boolean;
}

const BadgeSystemAvailable = (props: IBadgeSystemAvailableProps) => (
  <Container>
    <h3>
      <Badge variant={props.available ? "success" : "danger"}>
        {props.system}
      </Badge>
      <span className="ml-2">
        {props.available ? "Liberado" : "Indisponível"}
      </span>
    </h3>
  </Container>
);

export default BadgeSystemAvailable;
