import Badge from "react-bootstrap/Badge";

interface ISystems {
  sisbot_enabled: boolean;
  sisbolt_enabled: boolean;
  list_enabled: boolean;
  simulator_enabled: boolean;
}

const UserAvailableSystems = ({
  sisbot_enabled,
  sisbolt_enabled,
  list_enabled,
  simulator_enabled,
}: ISystems) => {
  return (
    <>
      <Badge variant={!list_enabled ? "secondary" : "success"} title="List">
        LIS
      </Badge>

      <Badge
        variant={!sisbot_enabled ? "secondary" : "success"}
        title="SISBot"
        className="ml-1"
      >
        SIS
      </Badge>

      <Badge
        variant={!sisbolt_enabled ? "secondary" : "success"}
        title="SISBolt"
        className="ml-1"
      >
        BOLT
      </Badge>

      <Badge
        variant={!simulator_enabled ? "secondary" : "success"}
        title="Simulator"
        className="ml-1"
      >
        SIM
      </Badge>
    </>
  );
};

export default UserAvailableSystems;
