import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";

type BadgeStatusType = {
    value: boolean | null;
    textForTrue: string;
    textForFalse: string;
    textForNull: string;
}

type DataType = {
    style: string;
    text: string;
}

export default function BadgeStatus({ value, textForTrue, textForFalse, textForNull }: BadgeStatusType): React.ReactElement {

    const [data, setData] = useState<DataType>({ style: '', text: '' });

    useEffect(() => {
        if (value === true) {
            setData({ style: 'success', text: textForTrue });
            return;
        }

        else if (value === false) {
            setData({ style: 'danger', text: textForFalse });
            return;
        }

        else setData({ style: 'secondary', text: textForNull });
    }, [value]);

    return <Badge variant={data.style}>{data.text}</Badge>
}