import React, { createContext, useState } from "react";
import IContextDefaultProps from "../interfaces/IContextDefaultProps";
import IUser from "../interfaces/IUser";

interface IUserContext {
  users: IUser[];
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
  findUserById(id: number): IUser | null;
}

export const UsersContext = createContext({} as IUserContext);

export const UsersProvider = ({ children }: IContextDefaultProps) => {
  const [users, setUsers] = useState<IUser[]>([]);

  const findUserById = (id: number): IUser | null => {
    const user = users.find((userdata) => userdata.id === id);
    return user as IUser;
  };

  return (
    <UsersContext.Provider value={{ users, setUsers, findUserById }}>
      {children}
    </UsersContext.Provider>
  );
};
