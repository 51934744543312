import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import IUserPasswordChange from "../../interfaces/IUserPasswordChange";
import handleConnectionError from "../../utils/handleConnectionError";
import api from "../../services/api";
import useAuth from "../../hooks/useAuth";
import addNotification from "../../utils/notifications";
import IConnectionMessage from "../../interfaces/IConnectionMessage";
import { useMemo } from "react";
import { useAppTranslation } from "../../contexts/TranslationContext";

const PasswordChange: React.FC = () => {
  const { axiosAuthToken } = useAuth();

  const { Translate } = useAppTranslation();

  const schema = useMemo(() => {
    return yup
      .object({
        password: yup.string().min(6, Translate('validations.password-min6')).required(),
        password_new: yup.string().min(6, Translate('validations.password-min6')).required(),
        password_new_confirmation: yup
          .string()
          .oneOf([yup.ref("password_new"), null], Translate('validations.password-must-match')),
      })
      .required();
  }, [Translate]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<IUserPasswordChange>({
    resolver: yupResolver(schema),
  });

  const handleChangePassword: SubmitHandler<IUserPasswordChange> = async (
    passwords
  ) => {
    try {
      const response = await api.put<IConnectionMessage>(
        "/password",
        passwords,
        axiosAuthToken
      );

      reset();
      addNotification({
        title: Translate('toast.done'),
        type: "success",
        message: response.data.message,
      });
    } catch (err) {
      handleConnectionError(err);
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Form onSubmit={handleSubmit(handleChangePassword)} noValidate>
            <h2 className="mb-3">{Translate('labels.change-password')}</h2>

            <Row className="mb-5">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group controlId="password">
                  <Form.Label>{Translate('labels.current-password')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("password")}
                    type="password"
                    readOnly={isSubmitting}
                    {...register("password")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group controlId="password_new">
                  <Form.Label>{Translate('labels.new-password')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("password_new")}
                    type="password"
                    readOnly={isSubmitting}
                    {...register("password_new")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password_new?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group controlId="password_new_confirmation">
                  <Form.Label>{Translate('labels.new-password-confirm')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("password_new_confirmation")}
                    type="password"
                    readOnly={isSubmitting}
                    {...register("password_new_confirmation")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password_new_confirmation?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Button type="submit" disabled={isSubmitting}>
                  {!isSubmitting ? (
                    Translate('actions.change-password')
                  ) : (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PasswordChange;
