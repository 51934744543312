import Switch from "react-switch";
import Badge from "react-bootstrap/Badge";
import { Container } from "./styles";

interface SwitchProps {
  checked: boolean;
  disabled: boolean;
  handleOnChange: any;
  badgeTitle: string;
}

const SwitchWithLabel = (settings: SwitchProps) => {
  const { checked, badgeTitle, disabled } = settings;

  return (
    <h4>
      <Container>
        <Switch
          checked={checked || false}
          onChange={settings.handleOnChange}
          disabled={disabled}
        />
        <Badge variant={checked ? "success" : "danger"} className="ml-2">
          {badgeTitle}
        </Badge>
      </Container>
    </h4>
  );
};

export default SwitchWithLabel;
