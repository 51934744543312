import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { AxiosResponse } from "axios";
import { FaCreditCard, FaFilter, FaList, FaSearch, FaSort, FaTrash } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import useAuth from "../../hooks/useAuth";
import api from "../../services/api";
import IUsersPaginated from "../../interfaces/IUsersPaginated";
import useUsers from "../../hooks/useUsers";
import UserAvailableSystems from "../../components/UserAvailableSystems";
import handleConnectionError from "../../utils/handleConnectionError";
import PaginationControl from "../../components/PaginationControl";
import { useAppTranslation } from "../../contexts/TranslationContext";

const Users: React.FC = () => {
  const { axiosAuthToken } = useAuth();
  const { users, setUsers } = useUsers();
  const [loading, setLoading] = useState(true);
  const [lastPage, setLastPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const { Translate } = useAppTranslation();

  const [usersPerPage, setUsersPerPage] = useState(() => {
    const storageValue = localStorage.getItem("@Aistenlab:usersPerPage");
    return storageValue ? Number.parseInt(storageValue) : 10;
  });

  const [sortingOrder, setSortingOrder] = useState(() => {
    const storageValue = localStorage.getItem("@Aistenlab:sortingOrder");
    return storageValue || "latests";
  });

  const [paymentRecurrencyType, setPaymentRecurrencyType] = useState('');

  const [searchField, setSearchField] = useState('name');

  const [currentPage, setCurrentPage] = useState(() => {
    const storageValue = localStorage.getItem("@Aistenlab:currentPage");
    return storageValue ? Number.parseInt(storageValue) : 1;
  });

  const handleResponseListUsers = (
    response: AxiosResponse<IUsersPaginated>
  ) => {
    const { data: usersArray, last_page } = response.data;
    setLastPage(last_page);
    setUsers(usersArray);
    setLoading(false);
  };

  const getUsers = () => {
    setLoading(true);
    api
      .get(
        `/users?page=${currentPage}&users_per_page=${usersPerPage}&sorting=${sortingOrder}`,
        axiosAuthToken
      )
      .then(handleResponseListUsers)
      .catch(handleConnectionError);
  };

  const handleSearchUser = useCallback(async () => {
    if (!searchTerm) return;

    setLoading(true);

    try {
      const response = await api.post(
        `/users/search?page=${currentPage}&users_per_page=${usersPerPage}&sorting=${sortingOrder}&payment_recurrency_type=${paymentRecurrencyType}`,
        {
          search: searchTerm,
          field: searchField
        },
        axiosAuthToken
      );

      const { data: usersArray, last_page } = response.data;
      setLastPage(last_page);
      setUsers(usersArray);
    } catch (error) {
      handleConnectionError(error);
    } finally {
      setLoading(false);
    }
  }, [axiosAuthToken, currentPage, paymentRecurrencyType, searchField, searchTerm, setUsers, sortingOrder, usersPerPage]);

  const handleClickSearchButton = useCallback(() => {
    setCurrentPage(1);
    handleSearchUser();
  }, [handleSearchUser]);

  const handleClearSearch = () => {
    setSearchTerm("");
    getUsers();
  };

  useEffect(() => {
    localStorage.setItem(
      "@Aistenlab:usersPerPage",
      JSON.stringify(usersPerPage)
    );
  }, [usersPerPage]);

  useEffect(() => {
    localStorage.setItem("@Aistenlab:sortingOrder", sortingOrder);
  }, [sortingOrder]);

  useEffect(() => {
    localStorage.setItem("@Aistenlab:currentPage", JSON.stringify(currentPage));
  }, [currentPage]);

  useEffect(() => {
    if (searchTerm !== "") handleSearchUser();
    else getUsers();
  }, [usersPerPage, sortingOrder, currentPage]);

  return (
    <>
      <h2 className="mb-3">{Translate('labels.manage-users')}</h2>

      <Form onSubmit={(event) => event.preventDefault()}>
        <Form.Row className="justify-content-md-end">
          <Col xs="auto">
            <Form.Label htmlFor="usersPerPage" srOnly>
              {Translate('labels.users-per-page')}
            </Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FaList />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                value={usersPerPage}
                onChange={({ target }) =>
                  setUsersPerPage(Number.parseInt(target.value))
                }
              >
                <option value={10}>10 {Translate('labels.per-page')}</option>
                <option value={20}>20 {Translate('labels.per-page')}</option>
                <option value={40}>40 {Translate('labels.per-page')}</option>
                <option value={60}>60 {Translate('labels.per-page')}</option>
                <option value={80}>80 {Translate('labels.per-page')}</option>
                <option value={100}>100 {Translate('labels.per-page')}</option>
                <option value={200}>200 {Translate('labels.per-page')}</option>
              </Form.Control>
            </InputGroup>
          </Col>

          <Col xs="auto">
            <Form.Label htmlFor="sortingOrder" srOnly>
              {Translate('labels.users-sorting')}
            </Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FaSort />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                value={sortingOrder}
                onChange={({ target }) => setSortingOrder(target.value)}
              >
                <option value="latests">{Translate('labels.latest')}</option>
                <option value="firsts">{Translate('labels.oldest')}</option>
              </Form.Control>
            </InputGroup>
          </Col>

          <Col>
            <Form.Label htmlFor="searchInput" srOnly>
              {Translate('actions.search')}
            </Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="searchInput"
                placeholder={Translate('actions.search')}
                value={searchTerm}
                onChange={({ target }) => setSearchTerm(target.value)}
              />
            </InputGroup>
          </Col>

          <Col xs="auto">
            <Form.Label htmlFor="searchField" srOnly>
              {Translate('labels.search-field')}
            </Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FaFilter />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                value={searchField}
                onChange={({ target }) => setSearchField(target.value)}
              >
                <option value="name">{Translate('labels.responsible')}</option>
                <option value="company">{Translate('labels.company')}</option>
                <option value="email">{Translate('labels.email')}</option>
                <option value="cpf">CPF</option>
                <option value="cnpj">CNPJ</option>

              </Form.Control>
            </InputGroup>
          </Col>

          <Col xs="auto">
            <Form.Label htmlFor="sortingOrder" srOnly>
              {Translate('labels.paymemt-recurrency-type')}
            </Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FaCreditCard />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                value={paymentRecurrencyType}
                onChange={({ target }) => setPaymentRecurrencyType(target.value)}
              >
                <option value="">-</option>
                <option value="mensal">{Translate('labels.monthly')}</option>
                <option value="anual">{Translate('labels.yearly')}</option>
              </Form.Control>
            </InputGroup>
          </Col>

          <Col xs="auto">
            <Button
              type="button"
              variant="danger"
              className="mb-2 mr-2"
              onClick={handleClearSearch}
            >
              <FaTrash />
            </Button>
            <Button type="submit" className="mb-2" onClick={handleClickSearchButton}>
              {Translate('actions.search')}
            </Button>
          </Col>
        </Form.Row>
      </Form>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{Translate('labels.responsible')}</th>
            <th>{Translate('labels.company')}</th>
            <th className="text-center">{Translate('labels.email')}</th>
            <th className="text-center">{Translate('labels.phone')}</th>
            <th className="text-center">{Translate('labels.systems')}</th>
            <th className="text-center">-</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={100} className="text-center">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </td>
            </tr>
          )}
          {!loading &&
            users.map((client) => (
              <tr key={client.id}>
                <td>{client.name}</td>
                <td>{client.company}</td>
                <td className="text-center">{client.email}</td>
                <td className="text-center">{client.phone}</td>
                <td className="text-center">
                  <UserAvailableSystems
                    sisbot_enabled={client.sisbot_enabled}
                    sisbolt_enabled={client.sisbolt_enabled}
                    list_enabled={client.list_enabled}
                    simulator_enabled={client.simulator_enabled}
                  />
                </td>
                <td className="text-center">
                  <Link to={`edit?id=${client.id}`}>{Translate('actions.edit')}</Link>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <PaginationControl
        currentPage={currentPage}
        lastPage={lastPage}
        handleClick={setCurrentPage}
      />
    </>
  );
};

export default Users;
