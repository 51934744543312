import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function LoginRedirector() {
    const { destinationRoute } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        navigate(destinationRoute);
    }, []);

    return <p>Redirecionando...</p>
}