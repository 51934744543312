import React, { useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import ILoginFields from "../../interfaces/ILoginFields";
import useAuth from "../../hooks/useAuth";
import IConnectionMessage from "../../interfaces/IConnectionMessage";
import handleConnectionError from "../../utils/handleConnectionError";
import api from "../../services/api";
import addNotification from "../../utils/notifications";
import { useAppTranslation } from "../../contexts/TranslationContext";

const PasswordRecovery: React.FC = () => {
  const { signed } = useAuth();
  const navigate = useNavigate();

  const { Translate } = useAppTranslation();

  const schema = useMemo(() => {
    return yup
      .object({
        email: yup
          .string()
          .email(Translate('validations.email-invalid'))
          .required(Translate('validations.email-required')),
      })
      .required();
  }, [Translate]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ILoginFields>({
    resolver: yupResolver(schema),
  });

  const handleStartPasswordRecovery: SubmitHandler<ILoginFields> = async (
    data
  ) => {
    try {
      const { email } = data;

      const response = await api.post<IConnectionMessage>(
        "/password/recovery",
        { email }
      );

      addNotification({
        title: "Código Enviado!",
        type: "success",
        message: response.data.message,
      });

      navigate("/password/new", { state: { email } });
    } catch (err) {
      handleConnectionError(err);
    }
  };

  return !signed ? (
    <Row className="justify-content-md-center mb-4">
      <Col xs={12} sm={12} md={6} lg={4} xl={4}>
        <h4 className="mb-4">{Translate('labels.recover-access')}</h4>

        <Form onSubmit={handleSubmit(handleStartPasswordRecovery)} noValidate>
          <Form.Group controlId="email">
            <Form.Label>
              {Translate('messages.ask-account-email-to-send-recovery-code')}
            </Form.Label>
            <Form.Control
              {...register("email")}
              disabled={isSubmitting}
              type="email"
              placeholder={Translate('labels.email-demo')}
              isInvalid={Object.keys(errors).includes("email")}
            />

            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" type="submit" block disabled={isSubmitting}>
            {!isSubmitting ? (
              Translate('actions.send-code')
            ) : (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            )}
          </Button>
        </Form>
      </Col>
    </Row>
  ) : (
    <Navigate replace to="/" />
  );
};

export default PasswordRecovery;
