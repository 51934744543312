import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { NavLink, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import addNotification from "../../utils/notifications";
import handleConnectionError from "../../utils/handleConnectionError";
import React from "react";
import { useAppTranslation } from "../../contexts/TranslationContext";
import { FaGlobe } from "react-icons/fa";

const NavbarHeader: React.FC = () => {
  const { signed, user, role, doLogout, destinationRoute } = useAuth();
  const navigate = useNavigate();
  const { Translate, changeLanguage } = useAppTranslation();

  const handleLogout = async () => {
    try {
      await doLogout();
      navigate("/login");

      addNotification({
        title: Translate('toast.see-ya'),
        message: Translate('messages.disconnected-successfully'),
        type: "info",
      });
    } catch (err) {
      handleConnectionError(err);
    }
  };

  return (
    <Navbar className="navbar navbar-expand navbar-light mb-5">
      <Navbar.Brand to={destinationRoute} as={NavLink}>
        Aistenlab
      </Navbar.Brand>

      <Nav className="mr-auto">
        {signed && (
          <>
            {signed && role === "admin" && (
              <Nav.Link to="/admin/users" as={NavLink}>
                {Translate('labels.users')}
              </Nav.Link>
            )}

            {signed && role === "client" && (
              <React.Fragment>
                {user?.user_type === 'company' && (
                  <React.Fragment>
                    <Nav.Link to="/employees" as={NavLink}>{Translate('labels.employees')}</Nav.Link>
                    <Nav.Link to="/invitations/sent" as={NavLink}>{Translate('labels.invites')}</Nav.Link>
                  </React.Fragment>
                )}

                {user?.user_type === 'employee' && (
                  <Nav.Link to="/invitations/received" as={NavLink}>
                    {Translate('labels.invites')}
                  </Nav.Link>
                )}
              </React.Fragment>
            )}
          </>
        )}
      </Nav>

      <DropdownButton
        as={ButtonGroup}
        key="lang"
        variant="default"
        id="dropdown-translation"
        title={<FaGlobe />}
      >
        <Dropdown.Item as={Button} onClick={() => changeLanguage('pt')}>Português</Dropdown.Item>
        <Dropdown.Item as={Button} onClick={() => changeLanguage('en')}>English</Dropdown.Item>
        <Dropdown.Item as={Button} onClick={() => changeLanguage('es')}>Spañol</Dropdown.Item>
      </DropdownButton>

      {signed && !!user && (
        <DropdownButton
          as={ButtonGroup}
          key="profile"
          variant="default"
          id="dropdown-profile"
          title={user.name}
        >
          <Dropdown.Item as={NavLink} to={`/${role}/profile`}>
            {Translate('labels.profile')}
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} to="password/change">
            {Translate('labels.change-password')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleLogout}>
            {Translate('actions.disconnect')}
          </Dropdown.Item>
        </DropdownButton>
      )}
    </Navbar>
  );
};

export default NavbarHeader;
