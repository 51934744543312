import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

interface RequireAuthProps {
  allowedRoles: Array<number>;
}

const RequireAuth = (props: RequireAuthProps) => {
  const { user } = useAuth();
  const location = useLocation();
  const allowedRole = props.allowedRoles.find((role) => user?.role === role);

  return allowedRole ? (
    <Outlet />
  ) : user ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
