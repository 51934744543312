import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import IConnectionMessage from "../../interfaces/IConnectionMessage";
import api from "../../services/api";
import handleConnectionError from "../../utils/handleConnectionError";
import { useAppTranslation } from "../../contexts/TranslationContext";

interface ActivationResponse {
  message: string;
  type: string;
}

const ActivateAccount = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [activationResult, setActivationResult] =
    useState<ActivationResponse | null>(null);

  const gotoLogin = () => navigate("/login");

  const { Translate } = useAppTranslation();

  useEffect(() => {
    const activationCode = searchParams.get("code");

    if (activationCode == null) {
      navigate("/");
      return;
    }

    api
      .post<IConnectionMessage>("/auth/activate", {
        activation_code: activationCode,
      })
      .then((response) => {
        setActivationResult({
          message: response.data.message,
          type: "success",
        });
      })
      .catch((err) => {
        handleConnectionError(err);
        setActivationResult({
          message: Translate('messages.account-activation-failed'),
          type: "danger",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [Translate]);

  return loading ? (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  ) : (
    activationResult && (
      <>
        <Alert variant={activationResult.type}>
          {activationResult.message}
        </Alert>

        <Button variant="primary" onClick={gotoLogin}>
          {Translate('labels.goto-login')}
        </Button>
      </>
    )
  );
};

export default ActivateAccount;
