import { Store } from "react-notifications-component";
import INotificationContent from "../interfaces/INotificationContent";

const addNotification = (settings: INotificationContent) => {
  Store.addNotification({
    title: settings.title,
    message: settings.message,
    type: settings.type,
    insert: "top",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export default addNotification;
