import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import useAuth from "../../hooks/useAuth";
import BadgeSystemAvailable from "../../components/BadgeSystemStatus";
import { Navigate } from "react-router-dom";
import { useAppTranslation } from "../../contexts/TranslationContext";

const ClientProfile: React.FC = () => {
  const { signed, user } = useAuth();

  const { Translate } = useAppTranslation();

  return signed && !!user ? (
    <Form>
      <Row className="mb-5">
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <h2 className="mb-3">{Translate('labels.registration-data')}</h2>

          <Row>
            <Col>
              <Form.Group controlId="name">
                <Form.Label>{Translate('labels.full-name')}</Form.Label>
                <Form.Control
                  type="text"
                  disabled={true}
                  placeholder={Translate('labels.client-name')}
                  value={user.name}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="company">
                <Form.Label>{Translate('labels.company')}</Form.Label>
                <Form.Control
                  type="text"
                  disabled={true}
                  placeholder={Translate('labels.company-name')}
                  value={user.company}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="email">
                <Form.Label>{Translate('email')}</Form.Label>
                <Form.Control
                  type="text"
                  disabled={true}
                  readOnly={true}
                  placeholder={Translate('labels.email-to-contact')}
                  value={user.email}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="phone">
                <Form.Label>{Translate('labels.phone')}</Form.Label>
                <Form.Control
                  type="text"
                  disabled={true}
                  placeholder={Translate('labels.phone')}
                  value={user.phone}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="person_type">
                <Form.Label>{Translate('labels.person-type')}</Form.Label>
                <Form.Control
                  type="text"
                  disabled={true}
                  value={
                    user.person_type === "legal_person" ? Translate('labels.legal-person') : Translate('labels.juridical-person')
                  }
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="cpf_cnpj">
                <Form.Label>
                  {user.person_type === "legal_person" ? "CPF" : "CNPJ"}
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={true}
                  value={user.cpf || user.cnpj}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="mac_address">
                <Form.Label>{Translate('labels.mac-address')}</Form.Label>
                <Form.Control
                  type="text"
                  disabled={true}
                  value={user.mac_address}
                />
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <h2 className="mb-3">{Translate('labels.systems')}</h2>
          <p>{Translate('labels.systems-description')}</p>

          <BadgeSystemAvailable system="LIST" available={user.list_enabled} />
          <BadgeSystemAvailable
            system="SISBOT"
            available={user.sisbot_enabled}
          />
          <BadgeSystemAvailable
            system="SIMULADOR BASIC"
            available={user.simulator_enabled}
          />
          <BadgeSystemAvailable
            system="SIMULADOR AR"
            available={user.simulator_ar_enabled}
          />
        </Col>
      </Row>
    </Form>
  ) : (
    <Navigate to="/" replace />
  );
};

export default ClientProfile;
