export const extractNumbersOnly = (strNumber: string): string => {
  return strNumber.replace(/[^\d]/g, "");
};

export const formatPhoneNumberToE164Standard = (strNumber: string) => {
  if (!strNumber) return '';

  const cleanNumber = strNumber.replace(/\(|\)|-|\s/g, '');

  const numberContainsPlusSign = cleanNumber.indexOf('+') > -1;

  return numberContainsPlusSign ? cleanNumber : `+${cleanNumber}`;
}