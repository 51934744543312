import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import handleConnectionError from "../../utils/handleConnectionError";
import api from "../../services/api";
import addNotification from "../../utils/notifications";
import IConnectionMessage from "../../interfaces/IConnectionMessage";
import IUserPasswordNew from "../../interfaces/IUserPasswordNew";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import IUserEmail from "../../interfaces/IUserEmail";
import { useEffect, useMemo } from "react";
import useAuth from "../../hooks/useAuth";
import { useAppTranslation } from "../../contexts/TranslationContext";

const PasswordNew: React.FC = () => {
  const { signed } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const { Translate } = useAppTranslation();

  const schema = useMemo(() => {
    return yup
      .object({
        password: yup.string().min(6, Translate('validations.password-min6')).required(),
        password_new: yup.string().min(6, Translate('validations.password-min6')).required(),
        password_new_confirmation: yup
          .string()
          .oneOf([yup.ref("password_new"), null], Translate('validations.password-must-match')),
      })
      .required()
  }, [Translate]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IUserPasswordNew>({
    resolver: yupResolver(schema),
  });

  const handleSetNewPassword: SubmitHandler<IUserPasswordNew> = async (
    passwords
  ) => {
    try {
      const response = await api.post<IConnectionMessage>(
        "/password/new",
        passwords
      );

      reset();
      addNotification({
        title: Translate('toast.done'),
        type: "success",
        message: response.data.message,
      });

      navigate("/login");
    } catch (err) {
      handleConnectionError(err);
    }
  };

  useEffect(() => {
    try {
      const { email } = location.state as IUserEmail;
      setValue("email", email);
    } catch (err) { }
  }, []);

  return !signed ? (
    <Row>
      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
        <Form onSubmit={handleSubmit(handleSetNewPassword)} noValidate>
          <h2 className="mb-3">{Translate('labels.change-password')}</h2>

          <Row className="mb-5">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group controlId="email">
                <Form.Label>{Translate('labels.email')}</Form.Label>
                <Form.Control
                  isInvalid={Object.keys(errors).includes("email")}
                  type="text"
                  readOnly={isSubmitting}
                  {...register("email")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group controlId="recovery_code">
                <Form.Label>{Translate('labels.recovery-code')}</Form.Label>
                <Form.Control
                  isInvalid={Object.keys(errors).includes("recovery_code")}
                  type="text"
                  readOnly={isSubmitting}
                  {...register("recovery_code")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.recovery_code?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group controlId="password">
                <Form.Label>{Translate('labels.new-password')}</Form.Label>
                <Form.Control
                  isInvalid={Object.keys(errors).includes("password")}
                  type="password"
                  readOnly={isSubmitting}
                  {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group controlId="password_confirmation">
                <Form.Label>{Translate('labels.new-password-confirm')}</Form.Label>
                <Form.Control
                  isInvalid={Object.keys(errors).includes("password_confirmation")}
                  type="password"
                  readOnly={isSubmitting}
                  {...register("password_confirmation")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password_confirmation?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col>
              <Button type="submit" disabled={isSubmitting}>
                {!isSubmitting ? (
                  Translate('actions.change-password')
                ) : (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  ) : (
    <Navigate to="/" />
  );
};

export default PasswordNew;
