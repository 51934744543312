import Button from 'react-bootstrap/Button';

type ButtonInlineType = {
    children: React.ReactNode;
    disabled?: boolean;
    handleClick: () => void;
}

export default function ButtonInline({ children, disabled = false, handleClick }: ButtonInlineType) {
    return (
        <Button size='sm' style={{ display: 'inline-flex' }} onClick={handleClick} disabled={disabled}>
            {children}
        </Button>
    );
}