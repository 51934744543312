import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import useAuth from "../../hooks/useAuth";
import React, { useCallback, useEffect, useState } from "react";
import api from "../../services/api";
import handleConnectionError from "../../utils/handleConnectionError";

import { formatDistance } from 'date-fns';
import { Col, Row, Spinner } from "react-bootstrap";
import BadgeStatus from "../../components/BadgeStatus";
import IInvitationReceived from "../../interfaces/invitations/IInvitationReceived";
import { FaCheck, FaSyncAlt, FaTimes } from "react-icons/fa";
import IBackendMessage from "../../interfaces/IBackendMessage";
import addNotification from "../../utils/notifications";
import ButtonInline from "../../components/ButtonInline";
import { useAppTranslation } from "../../contexts/TranslationContext";

const InvitationsReceived: React.FC = () => {
  const { user, axiosAuthToken, refreshUser } = useAuth();
  const { Translate, dateFnsLocale } = useAppTranslation();

  const [loadingInvitations, setLoadingInvitations] = useState(false);
  const [exitingFromCompany, setExitingFromCompany] = useState(false);

  const [invitationsReceived, setInvitationsReceived] = useState<IInvitationReceived[]>([]);
  const [invitationMarkedToAnswer, setInvitationMarkedToAnswer] = useState<IInvitationReceived | null>(null);

  const getInvitationsReceived = useCallback(async () => {
    try {
      setLoadingInvitations(true);
      const response = await api.get<IInvitationReceived[]>('/employee/invitations-received', axiosAuthToken);
      setInvitationsReceived(response.data);
    }
    catch (err) {
      handleConnectionError(err);
    } finally {
      setLoadingInvitations(false);
    }
  }, []);

  const answerInvitation = useCallback(async (invitation: IInvitationReceived, invitationAccepted: boolean) => {
    try {
      setInvitationMarkedToAnswer(invitation);

      const response = await api.post<IInvitationReceived>(`/employee/invitation-answer/${invitation.id}`, { "accepted": invitationAccepted }, axiosAuthToken);

      const { accepted } = response.data;

      if (accepted === false) {

        const updatedInvitationsReceived = invitationsReceived.map(currentInvitation => {
          if (currentInvitation.id === invitation.id) return response.data;
          return currentInvitation;
        });

        setInvitationsReceived(updatedInvitationsReceived);
      }

      else {

        const updatedInvitationsReceived = invitationsReceived.map(currentInvitation => {
          if (currentInvitation.id === invitation.id) return response.data;

          return ({ ...currentInvitation, accepted: false });
        });

        setInvitationsReceived(updatedInvitationsReceived);
        await refreshUser();
      }

    }
    catch (err) {
      handleConnectionError(err);
    }
    finally {
      setInvitationMarkedToAnswer(null);
    }
  }, [invitationsReceived]);

  const handleExitFromCurrentCompany = useCallback(async () => {

    try {
      setExitingFromCompany(true);
      const response = await api.patch<IBackendMessage>('/employee/exit-company', null, axiosAuthToken);
      await refreshUser();

      addNotification({ title: Translate('toast.done'), message: response.data.message, type: 'success' });
    }
    catch (err) {
      console.log(err);
      handleConnectionError(err);
    }
    finally {
      setExitingFromCompany(false);
    }
  }, [Translate, axiosAuthToken, refreshUser]);

  useEffect(() => {
    getInvitationsReceived();
  }, []);

  useEffect(() => {
    refreshUser();
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h3 className="mt-3">{Translate('labels.current-company')}</h3>

          {!!user && !!user?.linked_company && (
            <React.Fragment>
              <p>{Translate('labels.account-linked-to-company')} <b>{user.linked_company.name}.</b></p>
              <p>{Translate('labels.company-email-is')} <b>{user.linked_company.email}</b></p>

              {!exitingFromCompany && (
                <p>{Translate('labels.you-can-fire-from-company')} <a href='javascript:void(0)' onClick={handleExitFromCurrentCompany}>{Translate('actions.clicking-here')}</a>.</p>
              )}

              {exitingFromCompany && (
                <p><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-1" /> {Translate('progress.unlinking')}</p>
              )}
            </React.Fragment>
          )}

          {!!user && !user.linked_company && <p>{Translate('labels.not-linked-to-any-company')}</p>}
        </Col>
      </Row>


      <Row>
        <Col>
          <h3 className="mt-3">
            <span className="mr-2">{Translate('labels.invites-received')}</span>
            <ButtonInline handleClick={() => getInvitationsReceived()} disabled={loadingInvitations}>
              {
                !loadingInvitations
                  ? <FaSyncAlt size={12} />
                  : <span>{Translate('progress.updating')}</span>
              }
            </ButtonInline>
          </h3>
          <p>{Translate('messages.invites-auto-delelete-after-7-days')}</p>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{Translate('labels.company')}</th>
                <th>{Translate('labels.email')}</th>
                <th>Status</th>
                <th>{Translate('labels.received')}</th>
                <th className="text-center">{Translate('labels.ask-accept')}</th>
              </tr>
            </thead>
            <tbody>
              {invitationsReceived.map((invitation, index) => (
                <tr key={index}>

                  <td className="align-middle">{invitation.company.name}</td>

                  <td className="align-middle">{invitation.company.email}</td>

                  <td className="align-middle">
                    <BadgeStatus value={invitation.accepted} textForTrue={Translate('status.accepted')} textForFalse={Translate('status.denied')} textForNull={Translate('status.waiting-response')} />
                  </td>

                  <td className="align-middle">{formatDistance(new Date(invitation.created_at), new Date(), { addSuffix: true, locale: dateFnsLocale })}</td>

                  <td className='text-center'>{invitationMarkedToAnswer?.id === invitation.id
                    ? <Spinner as="span" animation="border" size="sm" className="ml-2" />
                    : invitation.accepted === null ? (
                      <React.Fragment>
                        <Button variant='success' size="sm" onClick={() => answerInvitation(invitation, true)}><FaCheck /></Button>
                        <Button variant='danger' size="sm" className="ml-2" onClick={() => answerInvitation(invitation, false)}><FaTimes /></Button>
                      </React.Fragment>
                    ) : '-'
                  }
                  </td>
                </tr>
              ))}

              {
                loadingInvitations && (
                  <tr className="text-center">
                    <td colSpan={100}><Spinner as="span" animation="border" size="sm" /> {Translate('progress.loading')}</td>
                  </tr>
                )
              }

              {
                !loadingInvitations && invitationsReceived.length === 0 && (
                  <tr className="text-center">
                    <td colSpan={100}>{Translate('labels.no-invites-received')}</td>
                  </tr>
                )
              }

            </tbody>
          </Table>
        </Col>
      </Row>

    </React.Fragment >
  )

};

export default InvitationsReceived;
