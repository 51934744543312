import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import ILoginFields from "../../interfaces/ILoginFields";
import useAuth from "../../hooks/useAuth";
import IConnectionMessage from "../../interfaces/IConnectionMessage";
import IAlert from "../../interfaces/IAlert";
import { useAppTranslation } from "../../contexts/TranslationContext";

const Login: React.FC = () => {
  const { signed, doLogin, destinationRoute } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [alertMessage, setAlertMessage] = useState<IAlert | null>(null);

  const { Translate } = useAppTranslation();

  const schema = useMemo(() => {
    return yup
      .object({
        email: yup.string().email().required(Translate('validations.email-required')),
        password: yup.string().min(6, Translate('validations.min-char-6')).required(),
      })
      .required();
  }, [Translate]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ILoginFields>({
    resolver: yupResolver(schema),
  });

  const handleLogin = useCallback<SubmitHandler<ILoginFields>>(async (data) => {
    const { email, password } = data;

    try {
      await doLogin({ email, password });
      navigate('/login/redirector');
    } catch (connectionError) {
      const err = connectionError as IConnectionMessage;
      setAlertMessage({ message: err.message, variant: "danger" });
    }

  }, [doLogin, navigate]);


  useEffect(() => {
    try {
      const { message, variant } = location.state as IAlert;
      setAlertMessage({ message, variant });
    } catch (err) { }
  }, []);

  return !signed ? (
    <Row className="justify-content-md-center mb-4">
      <Col xs={12} sm={12} md={6} lg={4} xl={4}>
        {!!alertMessage && (
          <Alert variant={alertMessage.variant}>{alertMessage.message}</Alert>
        )}

        <h4 className="mb-4">Login</h4>

        <Form
          onSubmit={handleSubmit(handleLogin)}
          noValidate
          validated={isValid}
        >
          <Form.Group controlId="email">
            <Form.Label>{Translate('labels.email')}</Form.Label>
            <Form.Control
              {...register("email")}
              type="email"
              placeholder={Translate('labels.enter-email')}
              isInvalid={Object.keys(errors).includes("email")}
            />

            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>{Translate('labels.password')}</Form.Label>
            <Form.Control
              {...register("password")}
              type="password"
              placeholder={Translate('labels.enter-password')}
              isInvalid={Object.keys(errors).includes("password")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" type="submit" block disabled={isSubmitting}>
            {!isSubmitting ? (
              Translate('actions.access-system')
            ) : (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            )}
          </Button>

          <Button
            variant="link"
            block
            disabled={isSubmitting}
            as={NavLink}
            to="/password/recovery"
          >
            {Translate('labels.forgot-password')}
          </Button>
          <Button
            variant="link"
            block
            disabled={isSubmitting}
            as={NavLink}
            to="/register"
          >
            {Translate('labels.no-register-create-now')}
          </Button>
        </Form>
      </Col>
    </Row>
  ) : (
    <Navigate replace to={destinationRoute} />
  );
};

export default Login;
