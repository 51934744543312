import axios, { AxiosError } from "axios";
import { createContext, useState, useEffect, useMemo, useCallback } from "react";
import IAuthContext from "../interfaces/IAuthContext";
import ILoginFields from "../interfaces/ILoginFields";
import ILoginResponse from "../interfaces/ILoginResponse";
import api from "../services/api";
import IContextDefaultProps from "../interfaces/IContextDefaultProps";
import handleConnectionError from "../utils/handleConnectionError";
import IUser from "../interfaces/IUser";
import roles from "../utils/roles";

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: IContextDefaultProps) => {
  const [user, setUser] = useState<IUser | null>(() => {
    const storage = localStorage.getItem("@Aistenlab:user");

    if (storage) return JSON.parse(storage);
    else return null;
  });

  const [token, setToken] = useState(() => {
    const storage = localStorage.getItem("@Aistenlab:token");

    if (storage) return storage;
    else return "";
  });

  const destinationRoute = useMemo<string>(() => (
    user?.role === 1 ? "/admin/users"
      : user?.user_type === 'company'
        ? '/employees'
        : '/invitations/received'

  ), [user]);

  useEffect(() => {
    localStorage.setItem("@Aistenlab:token", token);
  }, [token]);

  useEffect(() => {
    localStorage.setItem("@Aistenlab:user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    if (token) {
      api
        .get("/auth/authorized", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then()
        .catch(() => {
          setToken("");
          setUser(null);
        });
    }
  }, []);

  const handleSuccess = ({ token, user }: ILoginResponse) => {
    setToken(token);
    setUser(user);
  };

  const axiosAuthToken = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const doLogin = (credentials: ILoginFields): Promise<IUser | null> =>
    new Promise(async (resolve, reject) => {
      try {
        const { email, password } = credentials;
        const response = await api.post<ILoginResponse>("/auth/login", {
          email,
          password,
        });

        handleSuccess(response.data);
        resolve(response.data.user);
      } catch (err) {
        const errors = err as Error | AxiosError;

        if (!axios.isAxiosError(errors) || errors.response?.status === 0) {
          reject({ message: "Erro desconhecido na conexão." });
        } else reject(errors.response?.data);
      }
    });

  const doLogout = (): Promise<void> =>
    new Promise(async (resolve, reject) => {
      try {
        await api.get<ILoginResponse>("/auth/logout", axiosAuthToken);
        setToken("");
        setUser(null);
        resolve();
      } catch (err) {
        handleConnectionError(err);
        reject();
      }
    });

  const refreshUser = useCallback((): Promise<void> => {
    return new Promise<void>((resolve) => {
      api.get<IUser>('/me', axiosAuthToken)
        .then(response => {
          setUser(response.data);
          resolve();
        })
        .catch(handleConnectionError);
    });
  }, [axiosAuthToken]);

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        token,
        user,
        setUser,
        doLogin,
        doLogout,
        refreshUser,
        axiosAuthToken,
        destinationRoute,
        role: user?.role === roles.ADMIN ? "admin" : "client",
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
