import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./contexts/auth";
import { UsersProvider } from "./contexts/users";
import { ReactNotifications } from "react-notifications-component";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "react-notifications-component/dist/theme.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css';
import './style.css';

import { TranslationProvider } from "./contexts/TranslationContext";

import './i18n.config';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ReactNotifications />
    <BrowserRouter>
      <TranslationProvider>
        <AuthProvider>
          <UsersProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </UsersProvider>
        </AuthProvider>
      </TranslationProvider>
    </BrowserRouter>
  </React.StrictMode>
);
