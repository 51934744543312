import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useLocation, NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const BreadcrumControl = () => {
  const { signed } = useAuth();
  const location = useLocation();

  return signed ? (
    <Breadcrumb>
      {location.pathname.split("/").map((path) =>
        !!path ? (
          <Breadcrumb.Item active key={path}>
            {capitalize(path)}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item as={NavLink} to="/" key={path}>
            Home
          </Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  ) : (
    <></>
  );
};

export default BreadcrumControl;
