import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import NavbarHeader from "../NavbarHeader";
import BreadcrumControl from "../BreadcrumbControl";

const Layout = () => (
  <Container>
    <NavbarHeader />
    <BreadcrumControl />
    <Outlet />
  </Container>
);

export default Layout;
