import axios from "axios";
import isDev from "../utils/isDev";

const api = axios.create({
  baseURL: isDev() ? 'http://aistenlab-api.test' : 'https://api.aistenlab.com',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export default api;
