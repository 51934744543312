import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  h3 {
    display: flex;
    flex-direction: row;
    align-items: center;

    span:nth-child(2) {
      font-size: 15px;
      font-weight: bold;
    }
  }
`;
