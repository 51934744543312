import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import HCaptcha from "@hcaptcha/react-hcaptcha";
import InputMask from "react-input-mask";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import PhoneInput from 'react-phone-number-input';
import api from "../../services/api";
import addNotification from "../../utils/notifications";
import IUserRegister from "../../interfaces/IUserRegister";
import handleConnectionError from "../../utils/handleConnectionError";
import IConnectionMessage from "../../interfaces/IConnectionMessage";
import { useNavigate } from "react-router-dom";
import IAlert from "../../interfaces/IAlert";
import isDev from "../../utils/isDev";
import HCaptchaKey from "../../utils/HCaptchaKey";
import isValidCPF from "../../utils/isValidCPF";
import isValidCNPJ from "../../utils/isValidCNPJ";
import { useAppTranslation } from "../../contexts/TranslationContext";

const Register: React.FC = () => {

  const { Translate, getLanguageCodeToReactPhoneNumberInput, selectedLanguage } = useAppTranslation();


  const schema = useMemo(() => {
    return yup
      .object({
        name: yup.string().required(Translate('validations.name-required')),
        country: yup.string().required(Translate('validations.country-required')),
        region: yup.string().required(Translate('validations.region-required')),
        address: yup.string().required(Translate('validations.address-required')),
        company: yup.string().required(Translate('validations.company-required')),
        email: yup
          .string()
          .email(Translate('validations.email-invalid'))
          .required(Translate('validations.email-required')),
        password: yup.string().min(6, Translate('validations.password-min6')).required(),
        password_confirmation: yup
          .string()
          .oneOf([yup.ref("password"), null], Translate('validations.password-must-match')),
        phone: yup.string().required(Translate('validations.phone-required')),
        person_type: yup.string().required(Translate('validations.person-type-required')),

        cpf: yup.string().when(["person_type"], {
          is: (person_type: string) => person_type === "legal_person",
          then: yup
            .string()
            .required(Translate('validations.cpf-required'))
            .test("test-valid-cpf", Translate('validations.cpf-invalid'), isValidCPF),
        }),
        cnpj: yup.string().when(["person_type"], {
          is: (person_type: string) => person_type === "juridical_person",
          then: yup
            .string()
            .required(Translate('validations.cnpj-required'))
            .test("test-valid-cnpj", Translate('validations.cnpj-invalid'), isValidCNPJ),
        }),
        other_identification: yup.string().when(["person_type"], {
          is: (person_type: string) => person_type === "other_identification",
          then: yup
            .string()
            .required(Translate('validations.field-required')),
        }),

        hcaptcha: yup.string().required("Resolva o desafio captcha."),
      })
      .required();
  }, [Translate]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IUserRegister>({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const captchaRef = useRef<HCaptcha | null>(null);
  const watchPersonType = watch("person_type", "");
  const watchCountry = watch("country", "");

  const handleCreateNewUser: SubmitHandler<IUserRegister> = async (
    userdata
  ) => {
    try {
      const response = await api.post<IConnectionMessage>('/users', userdata);
      addNotification({
        type: "success",
        title: Translate('toast.done'),
        message: response.data.message,
      });

      const message: IAlert = {
        message: Translate('toast.registration-done-check-your-email'),
        variant: "success",
      };

      navigate("/login", {
        state: message,
      });
    } catch (err) {
      const serverMessage = handleConnectionError(err);
      console.info(serverMessage);
      if (serverMessage.errors) {
        const fields = serverMessage.errors as IUserRegister;

        addNotification({
          type: "danger",
          title: Translate('toast.check'),
          message: fields.email,
        });
      }
    } finally {
      captchaRef.current?.resetCaptcha();
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "person_type" && type === "change") {
        setValue("cpf", "");
        setValue("cnpj", "");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setValue("phone", "");
  }, []);



  return (
    <>
      <Form onSubmit={handleSubmit(handleCreateNewUser)} noValidate>
        <Row className="mb-5">
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <h2 className="mb-3">{Translate('labels.registration-data')}</h2>

            <Row>
              <Col>
                <Form.Group controlId="name">
                  <Form.Label>{Translate('labels.full-name')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("name")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="company">
                  <Form.Label>{Translate('labels.company-name')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("company")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("company")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.company?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="country">
                  <Form.Label>{Translate('labels.country')}</Form.Label>
                  <Controller
                    control={control}
                    name="country"
                    render={({ field: { value, onChange } }) => (
                      <CountryDropdown
                        defaultOptionLabel={Translate('labels.select')}
                        value={value}
                        onChange={onChange}
                        classes="form-control" />
                    )}
                  />

                  {!!errors.country && <div className="invalid-feedback d-block">{errors.country.message}</div>}
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="region">
                  <Form.Label>{Translate('labels.region')}</Form.Label>
                  <Controller
                    control={control}
                    name="region"
                    render={({ field: { value, onChange } }) => (
                      <RegionDropdown
                        defaultOptionLabel={Translate('labels.select')}
                        disableWhenEmpty
                        country={watchCountry}
                        value={value}
                        onChange={onChange}
                        classes="form-control" />
                    )}
                  />

                  {!!errors.region && <div className="invalid-feedback d-block">{errors.region.message}</div>}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="address">
                  <Form.Label>{Translate('labels.address')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("address")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("address")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>{Translate('labels.email')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("email")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="phone">
                  <Form.Label>{Translate('labels.phone')}</Form.Label>
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field: { value, onChange } }) => (
                      <div className="form-group">

                        <PhoneInput
                          disabled={isSubmitting}
                          value={value}
                          onChange={onChange}
                          labels={getLanguageCodeToReactPhoneNumberInput(selectedLanguage)}
                          className={`form-control ${!!errors.phone ? 'is-invalid' : ''}`}
                        />

                        {
                          !!errors.phone && (
                            <div className="invalid-feedback d-block">{errors.phone.message}</div>
                          )
                        }
                      </div>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>{Translate('labels.billing-email')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("billing_email")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("billing_email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.billing_email?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="password">
                  <Form.Label>{Translate('labels.password')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("password")}
                    type="password"
                    readOnly={isSubmitting}
                    {...register("password")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="password_confirmation">
                  <Form.Label>{Translate('labels.password-confirm')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("password_confirmation")}
                    type="password"
                    readOnly={isSubmitting}
                    {...register("password_confirmation")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password_confirmation?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="person_type">
                  <Form.Label>{Translate('labels.person-type')}</Form.Label>
                  <Form.Control
                    disabled={isSubmitting}
                    as="select"
                    {...register("person_type")}
                  >
                    <option value="legal_person">{Translate('labels.legal-person')}</option>
                    <option value="juridical_person">{Translate('labels.juridical-person')}</option>
                    <option value="other_identification">{Translate('labels.other')}</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                {/* CPF */}
                {watchPersonType === "legal_person" && (
                  <Form.Group controlId="cpf">
                    <Form.Label>CPF</Form.Label>
                    <Controller
                      control={control}
                      name="cpf"
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Form.Control
                            disabled={isSubmitting}
                            isInvalid={Object.keys(errors).includes("cpf")}
                            as={InputMask}
                            mask="999.999.999-99"
                            value={value}
                            onChange={onChange}
                            placeholder="000.000.000-00"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cpf?.message}
                          </Form.Control.Feedback>
                        </>
                      )}
                    />
                  </Form.Group>
                )}

                {/* CNPJ */}
                {watchPersonType === "juridical_person" && (
                  <Form.Group controlId="cnpj">
                    <Form.Label>CNPJ</Form.Label>
                    <Controller
                      control={control}
                      name="cnpj"
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Form.Control
                            disabled={isSubmitting}
                            isInvalid={Object.keys(errors).includes("cnpj")}
                            as={InputMask}
                            placeholder="00.000.000/0000-00"
                            mask="99.999.999/9999-99"
                            value={value}
                            onChange={onChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cnpj?.message}
                          </Form.Control.Feedback>
                        </>
                      )}
                    />
                  </Form.Group>
                )}

                {/* other_identification */}
                {watchPersonType === "other_identification" && (
                  <Form.Group controlId="other_identification">
                    <Form.Label>{Translate('labels.unique-identifier')}</Form.Label>
                    <Controller
                      control={control}
                      name="other_identification"
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Form.Control
                            disabled={isSubmitting}
                            isInvalid={Object.keys(errors).includes("other_identification")}
                            value={value}
                            onChange={onChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.other_identification?.message}
                          </Form.Control.Feedback>
                        </>
                      )}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <h2 className="mb-3">{Translate('labels.confirmation')}</h2>

            <p>{Translate('messages.solve-captcha-message')}</p>

            <HCaptcha
              sitekey={isDev() ? HCaptchaKey.TEST : HCaptchaKey.PROD}
              // onLoad={handleHcaptchaLoad}
              onVerify={(token) => setValue("hcaptcha", token)}
              ref={captchaRef}
            />

            {<p> {errors.hcaptcha?.message}</p>}

            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {!isSubmitting ? (
                Translate('actions.create-registry')
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Register;
