import Pagination from "react-bootstrap/Pagination";

interface IPaginationControl {
  currentPage: number;
  lastPage: number;
  handleClick(pageNumber: number): void;
}

const PaginationControl = (settings: IPaginationControl) => (
  <Pagination>
    {[...Array(settings.lastPage)]
      .map((_, index) => index + 1)
      .map((page) => (
        <Pagination.Item
          key={page}
          active={page === settings.currentPage}
          onClick={() => settings.handleClick(page)}
        >
          {page}
        </Pagination.Item>
      ))}
  </Pagination>
);

export default PaginationControl;
