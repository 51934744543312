import { useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import useAuth from "../../hooks/useAuth";
import IUserEdit from "../../interfaces/IUserEdit";
import * as yup from "yup";
import api from "../../services/api";
import handleConnectionError from "../../utils/handleConnectionError";
import IConnectionMessage from "../../interfaces/IConnectionMessage";
import addNotification from "../../utils/notifications";
import IUser from "../../interfaces/IUser";
import { useAppTranslation } from "../../contexts/TranslationContext";

const AdminProfile: React.FC = () => {
  const { user, setUser, axiosAuthToken } = useAuth();

  const { Translate } = useAppTranslation();

  const schema = useMemo(() => {
    return yup
      .object({
        name: yup.string().required(Translate('validations.name-required')),
        email: yup
          .string()
          .email(Translate('validations.email-invalid'))
          .required(Translate('validations.email-required')),
      })
      .required();
  }, [Translate]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<IUserEdit>({
    resolver: yupResolver(schema),
  });

  const handleUpdate: SubmitHandler<IUserEdit> = async (userdata) => {
    const { name, email } = userdata;

    try {
      const response = await api.put<IConnectionMessage>(
        "/admin/profile",
        { name, email },
        axiosAuthToken
      );

      setUser({ ...user, name, email } as IUser);

      addNotification({
        title: Translate('toast.done'),
        message: response.data.message,
        type: "success",
      });
    } catch (err) {
      handleConnectionError(err);
    }
  };

  useEffect(() => {
    if (user) reset(user);
  }, []);

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Form onSubmit={handleSubmit(handleUpdate)} noValidate>
            <h2 className="mb-3">{Translate('labels.admin')}</h2>

            <Row className="mb-5">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group controlId="name">
                  <Form.Label>{Translate('labels.name')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("name")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group controlId="email">
                  <Form.Label>{Translate('labels.email')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("email")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Button type="submit" disabled={isSubmitting}>
                  {!isSubmitting ? (
                    Translate('actions.update-register')
                  ) : (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AdminProfile;
