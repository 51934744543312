import Table from "react-bootstrap/Table";
import useAuth from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import api from "../../services/api";
import handleConnectionError from "../../utils/handleConnectionError";

import { Spinner } from "react-bootstrap";
import addNotification from "../../utils/notifications";
import IBackendMessage from "../../interfaces/IBackendMessage";
import IInvitationData from "../../interfaces/invitations/IInvitationData";
import ButtonInline from "../../components/ButtonInline";
import { FaSyncAlt } from "react-icons/fa";
import { useAppTranslation } from "../../contexts/TranslationContext";

const CompanyEmployees: React.FC = () => {
  const { signed, user, axiosAuthToken } = useAuth();
  const { Translate } = useAppTranslation();

  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [employees, setEmployees] = useState<IInvitationData[]>([]);
  const [employeeMarkedToRemove, setEmployeeMarkedToRemove] = useState<IInvitationData | null>(null);

  const getEmployees = useCallback(async () => {
    try {
      setLoadingEmployees(true);

      const response = await api.get<IInvitationData[]>('/company/employees', axiosAuthToken);
      setEmployees(response.data);
    }
    catch (err) {
      handleConnectionError(err);
    }
    finally {
      setLoadingEmployees(false)
    }
  }, [axiosAuthToken]);

  const removeEmployee = useCallback(async (employee: IInvitationData) => {
    try {
      setEmployeeMarkedToRemove(employee);

      const response = await api.delete<IBackendMessage>(`/company/remove-employee/${employee.id}`, axiosAuthToken);
      const updatedEmployees = employees.filter(currentEmployee => currentEmployee.id !== employee.id);

      setEmployees(updatedEmployees);
      addNotification({ title: Translate('toast.removed'), message: response.data.message, type: 'success' });
    }
    catch (err) {
      handleConnectionError(err);
    }
    finally {
      setEmployeeMarkedToRemove(null);
    }
  }, [Translate, axiosAuthToken, employees]);

  useEffect(() => {
    getEmployees();
  }, []);

  return signed && !!user ? (
    <React.Fragment>
      <h3 className="mt-3">
        <span className="mr-2">{Translate('labels.employees')}</span>
        <ButtonInline handleClick={() => getEmployees()} disabled={loadingEmployees}>
          {!loadingEmployees ? <FaSyncAlt /> : <span>{Translate('progress.updating')}</span>}
        </ButtonInline>
      </h3>
      <p>{Translate('labels.users-below-linked-to-this-company')}</p>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{Translate('labels.name')}</th>
            <th>{Translate('labels.email')}</th>
            <th>{Translate('actions.remove')}</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee, index) => (
            <tr key={index}>

              <td>{employee.name}</td>

              <td>{employee.email}</td>

              <td>
                {
                  employeeMarkedToRemove?.id === employee.id

                    ? <Spinner as="span" animation="border" size="sm" />

                    : <a href='javascript:void(0);' onClick={() => removeEmployee(employee)}>
                      {Translate('actions.fire-employee')}
                    </a>
                }

              </td>
            </tr>
          ))}

          {
            loadingEmployees && (
              <tr className="text-center">
                <td colSpan={100}><Spinner as="span" animation="border" size="sm" /> {Translate('progress.loading')}</td>
              </tr>
            )
          }

          {
            !loadingEmployees && employees.length === 0 && (
              <tr className="text-center">
                <td colSpan={100}>{Translate('labels.no-linked-employees')}</td>
              </tr>
            )
          }

        </tbody>
      </Table>

    </React.Fragment >
  ) : <Navigate to="/" replace />;

};

export default CompanyEmployees;
